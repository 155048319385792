/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable max-len */
import React from 'react';
import styled from 'styled-components';


const PhoneSubscirptionPrivacyPolicy: React.FC = () => {
  React.useEffect(() => {
    window.history.scrollRestoration = 'manual';

    window.scrollTo(0, 0);
  }, []);

  return (
    <Wrapper>
      <MainHeading>Phone Subscription Terms and Conditions</MainHeading>
      <Text>Last modified: 22/10/2024</Text>
      <div>
        <Text>
         When you opt in to the service, You are subscribed to Zero5 alerts. Msg & data rates may apply.
        </Text>
        <Text>
        You can cancel the SMS or MMS service at any time by texting 'STOP' to phone number. When you send the message 'STOP' to us, you will receive a confirmation SMS that you have been unsubscribed. After this, you will no longer receive any SMS or MMS messages from us. If you wish to re-subscribe, simply sign in and accept the subscription as you did before, and we will resume sending you SMS and MMS messages        </Text>
        <Text>
        You can get more information at any time by texting "HELP" to phone number. When you send the SMS message "HELP" to us, we respond with instructions on how to use our service and how to unsubscribe.
        </Text>
        <Text>
        We are able to deliver messages to the following mobile phone carriers: Major carriers: AT&T, Verizon Wireless, Sprint, T-Mobile, MetroPCS, US Cellular, Alltel, Boost Mobile, Nextel, and Virgin Mobile. Minor carriers: Alaska Communications Systems (ACS), Appalachian Wireless (EKN), Bluegrass Cellular, Cellular One of East Central IL (ECIT), Cellular One of Northeast Pennsylvania, Cincinnati Bell Wireless, Cricket, Coral Wireless (Mobi PCS), COX, Cross, Element Mobile (Flat Wireless), Epic Touch (Elkhart Telephone), GCI, Golden State, Hawkeye (Chat Mobility), Hawkeye (NW Missouri), Illinois Valley Cellular, Inland Cellular, iWireless (Iowa Wireless), Keystone Wireless (Immix Wireless/PC Man), Mosaic (Consolidated or CTC Telecom), Nex-Tech Wireless, NTelos, Panhandle Communications, Pioneer, Plateau (Texas RSA 3 Ltd), Revol, RINA, Simmetry (TMP Corporation), Thumb Cellular, Union Wireless, United Wireless, Viaero Wireless, and West Central (WCC or 5 Star Wireless). Carriers are not liable for delayed or undelivered messages.
        </Text>
        <Text>
        Message and data rates may apply for any messages that we send to you or you send to us. Contact your wireless provider for more information about your text plan or data plan. If you have questions about the services provided by this short code, email us at support@zero5.co.
        </Text>
        <Text>
        If you have any questions regarding privacy, please read our privacy policy: <StyledLink href="/policies/privacy-policy">Privacy Policy</StyledLink>
        </Text>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  max-width: 800px;
  margin: 0 auto 100px;
  padding: 20px;
  font-weight: 300;
`;

const MainHeading = styled.h1`
  margin: 0 0 30px 0;
  text-align: center;
  font-size: 1.846rem;
  font-family: inherit;
  font-weight: 400;
  line-height: 1.167;
`;

const Text = styled.p`
  margin: 0 0 10px 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;

  &:last-child {
    margin: 0;
  }
`;

const StyledLink = styled.a`
  color: #386344;
  font-size: 12px;
`;

const Heading = styled.h2<{ inline?: boolean; }>`
  display: ${({ inline }) => (inline ? 'inline' : 'block')};
  margin: 0 10px 10px 0;
  font-size: 16px;
  font-weight: 500;
`;


export default PhoneSubscirptionPrivacyPolicy;
