import { useMemo } from 'react';

import Spacer from 'components/Spacer/Spacer';
import Typography from 'components/Typography/Typography';
import theme from 'theme/theme';
import { viewportBreakpoints } from 'utils/constants';
import useViewport from 'utils/hooks/useViewport';

import MacbookImage from '../../assets/Macbook.png';
import { S } from './SSolutionWelcome';

const SolutionWelcome = () => {
  const { width } = useViewport();

  const isMobile = useMemo(
    () => width < parseInt(viewportBreakpoints.tablet),
    [width]
  );

  return (
    <S.Container>
      <S.Label label='Solution' />
      <S.Image src={MacbookImage} alt='Our solution' />
      <S.RightSection>
        <S.TextContent>
          <Typography
            lineHeight={isMobile ? 26 : 34.5}
            fontWeight={500}
            fontColor={theme.colors.Yellow}
            fontSize={isMobile ? 'fz24' : 'fz28'}
            text='Tailored parking management solution. Made just for you.'
            // text='Everything you need to make parking run itself.'
          />
          <Spacer height={20} />
          <p style={{ opacity: isMobile ? 0.4 : 1 }}>
            <Typography
              fontWeight='300'
              fontSize={isMobile ? 'fz14' : 'fz18'}
              lineHeight={isMobile ? '18' : '26'}
              text='Every property is unique. Smart parking solutions must be designed with your property
                in mind to benefit you and your customers. This is why our solutions are made
                just for you. Instead of a one-size-fits-all approach, we customize our pricing
                and solutions to fit your needs.'
            />
          </p>

          {isMobile && <Spacer height={32} />}

          <S.LearnMoreButton
            width={isMobile ? 222 : 313}
            onClick={() => {
              console.log(1);
            }}
          />

          {isMobile && <Spacer height={77} />}
        </S.TextContent>
      </S.RightSection>
    </S.Container>
  );
};

export default SolutionWelcome;
